import React from 'react'
import Container from '@packages/justo-parts/lib/components/Container'
import SearchBox from '@page-components/Layout/Website/Layouts/Default/NavbarCPG/SearchBox'

import styles from './styles.module.css'

export default function SearchProduct() {
  const layout = {
    productsLayout: true
  }

  return (
    <Container className={styles.container}>
      <SearchBox layout={layout} />
    </Container>
  )
}
